.mainCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.largescreen {
  display: flex;
  width: 100%;
}

.largescreen>div {
  flex: 1;
  margin-right: 10px;
}

.fullwidth {
  width: 100%;
  overflow: auto;
  margin-top: 20px;
  overflow: auto !important;
}

@media screen and (max-width: 768px) {
  .largescreen {
    flex-direction: column;
  }

  .largescreen>div {
    margin-right: 0;
    margin-bottom: 10px;
  }
}