body {
  margin: 0;
  padding: 0;
}

.maindiv {
  background-color: white;
  margin: auto;
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 90vw;
  height: 90vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 8px;
  padding: 25px;
}

.card1 form>* {
  margin: 10px 0;
}

.loginImage {
  height: 100%;
  width: 100%;
}

.card1 {
  width: 50% !important;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 10px 0px 20px;
}

.card1>div {
  width: 90%;
  margin: auto;
}

.imgheader {
  display: flex;
  flex-direction: row;
  justify-content: space-around;


  /* border:1px solid red; */
}

.imgheader img {
  height: 220px;
  width: 220px;
}

.imgheader h1 {
  display: inline;
  align-self: center;
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
  color: black;
}

.card1 p {
  font-size: 1rem;
  font-weight: 400;
  color: rgb(155, 154, 154);
  /* padding: 0.4rem 2rem 0.4rem 2rem; */
}

.card1 form {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  padding: 1rem;
}

.input {
  border: none;
  outline: none;
  font-size: 1.1rem;
  font-weight: 400;
  color: #000;
  height: 20px;
  margin-top: 40px;
}

.form_group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btn {
  background-color: rgb(8, 155, 171);
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  height: 50px;
  border-radius: 5px;
  outline: none;
  font-size: 1.1rem;
  font-weight: 800;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.checkbox label {
  font-size: 1.1rem;
  font-weight: 400;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.checkbox input {
  width: 1.1rem;
  height: 1.1rem;
  border: 1px solid #c0c1c2;
  border-radius: 0.2rem;
  outline: none;
  font-size: 1.1rem;
  font-weight: 400;
  color: #000;
  padding: 0.4rem 2rem 0.4rem 2rem;
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.login_slider {
  border-radius: 22px;
}

@media (max-width: 768px) {
  .loginImage {
    display: none;
  }

  .card1 {
    width: 100% !important
  }

  .maindiv {
    width: 100vw !important;
    display: flex;
    align-items: center;
    height: 100vh !important;
  }

  .btn {
    margin-left: 0;
  }
}

@media (max-width: 1224px) {
  .loginImage {
    display: none;
  }

  .imgheader img {
    height: 250px;
    width: 250px;
  }

  .card1 {
    width: 60% !important
  }

  .maindiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100vw !important;
    height: 100vh !important;
    box-shadow: none !important;
    margin: auto;

  }
}

@media (max-width: 992px) {
  .card1 {
    width: 80% !important
  }
}

@media (max-width: 576px) {
  .loginImage {
    display: none;
  }

  .card1 {
    width: 100% !important;
    padding: 0;
  }

  .maindiv {
    display: flex;
    justify-content: space-evenly;
    width: 100vw !important;
    height: 100vh !important;
    margin: auto;
    padding: 10px;
  }
}